import React,{ useState, useEffect } from 'react';
import '../component-css/Form.css';
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import  Spinner from '../../images/Spinner.gif';
import axios from 'axios';
import { protectedResources } from "../common/authConfig";
import { callApiWithToken } from "../../certapi/fetchCert";
import { callDynamoDB } from "../../certapi/fetchCertDdb";
import '../component-css/FormTable.css';

export default function Form() {

    const [data, setData] = useState({investigation_id : "00000000000", instance_id : "i-00000000000000000", os_type1 : "Windows", delete_stack : "empty"});  
    const [outputData, setOutputData] = useState({});
    const [outputFlag, setOutputFlag] = useState(false);
    const [status, setStatus] = useState("Initiated");
    const [message, setMessage] = useState("Initiated")
    const [isLoading, setIsLoading] = useState(true);
    const [ exectId, setExecId] = useState();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [applicationData, setApplicationData] = useState(null);
    const [ finalMsg, setFinalMsg] = useState();
    const statusMap = new Map([
       ["initiating", "initiated"],
       ["existInstanceDetail", "Instance found and process begin"],
       ["noInstanceDetail", "Instance detail not exist in Snow Table"],
       ["copyInitialSnaphot", "copy of Initial Snapshot creation started"],
       ["cfnCreationInitiated", "Stack creation for New Instance begin"],
       ["complete", "Completed"]
    ]);

    let x;
    
    let dbCall = async function (dbToken,url, id){

        const response=  await callDynamoDB(dbToken, url, id, axios);
       
        let result= response.Status[0].Progress;
        setStatus(response.Status[0].Progress)
        setMessage(statusMap.get(result));
         window.sessionStorage.setItem('progress', (response.Status[0].Progress));
        if (result==="complete" || result==="noInstanceDetail"){ 
            clearInterval(x);
            setIsLoading(false);
            window.sessionStorage.setItem('isLoading', false);
            (result==="complete")?setFinalMsg("Completed"):setFinalMsg("Failed");
            window.sessionStorage.clear()
        }
                                     
    }    

    useEffect(() => {
        
        let inv_id = window.sessionStorage.getItem('investigation_id')===null?data.investigation_id: window.sessionStorage.getItem('investigation_id');
        let ins_id = window.sessionStorage.getItem('instance_id')===null?data.instance_id: window.sessionStorage.getItem('instance_id');
        let os_type1 = window.sessionStorage.getItem('os_type1')===null?data.os_type1: window.sessionStorage.getItem('os_type1');
        let del_stk = window.sessionStorage.getItem('delete_stack')===null?data.delete_stack:window.sessionStorage.getItem('delete_stack');
        let out_flag =  window.sessionStorage.getItem('outputFlag');
        let executionID = window.sessionStorage.getItem('exectId');
        setData({investigation_id: inv_id, instance_id: ins_id, os_type1: os_type1, delete_stack: del_stk});
        setOutputFlag(out_flag);
        setExecId(executionID);
        window.sessionStorage.getItem('progress') && setStatus(window.sessionStorage.getItem('progress'));
        setMessage(statusMap.get(window.sessionStorage.getItem('progress')));
        let load = window.sessionStorage.getItem('isLoading');
        load === "false" ? setIsLoading(false):setIsLoading(true);
    
        let dbToken=window.sessionStorage.getItem('db_token')

        let y;
        
        y=setInterval(dbCall,30000,dbToken, protectedResources.apiCertDdb.endpoint,inv_id);
        return () => clearInterval(y);
    },[]);

        function investigationHandler(e){
            setData({...data, investigation_id: e.target.value});
            window.sessionStorage.setItem('investigation_id', e.target.value);
            
        }

        function instanceHandler(e){
            setData({...data, instance_id: e.target.value});
            window.sessionStorage.setItem('instance_id', e.target.value);
           
        }

        
        function osTypeHandler(e){
            setData({...data, os_type1: e.target.value});
            window.sessionStorage.setItem('os_type1', e.target.value);
        }

        function delStackHandler(e){
            setData({...data, delete_stack: e.target.value});
            window.sessionStorage.setItem('delete_stack', e.target.value);
        }

        function submitHandler(event){
            event.preventDefault();
            if (data.investigation_id.match(/^([a-z0-9]){10,}$/i)===null){
                document.getElementById("invId").style.display='';
                document.getElementById("instId").style.display='none';           	
            }
            else if (data.instance_id.match(/^i-([a-z0-9]){10,17}$/i)===null){
                document.getElementById("instId").style.display='';
                document.getElementById("invId").style.display='none';
            }

            else{
                document.getElementById("invId").style.display='none';
                document.getElementById("instId").style.display='none';
                setOutputFlag(false);
                setIsLoading(true);
                setStatus('initiating');
                window.sessionStorage.setItem('isLoading', true);
                window.sessionStorage.setItem('outputFlag', false);
                window.sessionStorage.setItem('progress', 'initiating');
                let dbToken;
                if (account && inProgress === "none" && !applicationData) {
                    instance.acquireTokenSilent({
                        scopes: protectedResources.apiCert.scopes,
                        account: account
                    }).then((response) => {
                        let payload ={
                            "instanceid": data.instance_id,
                            "ostype": data.os_type1,
                            "investigationid": data.investigation_id,
                            "teardown": data.delete_stack
                        }
                        //alert(JSON.stringify(payload));
                        dbToken=response.idToken;
                        window.sessionStorage.setItem('db_token',dbToken);
                        callApiWithToken(response.idToken, protectedResources.apiCert.endpoint, payload)
                            .then(async (response) => {
                                setOutputFlag(true);
                                setOutputData(response);
                                window.sessionStorage.setItem('outputFlag', true);
                                setExecId(response.executionId);
                                window.sessionStorage.setItem('exectId', response.executionId);
                                
                                x = setInterval(dbCall, 30000, dbToken,protectedResources.apiCertDdb.endpoint,data.investigation_id );
                            
                            });
                    }).catch((error) => {
                        window.sessionStorage.clear();
                        // in case if silent token acquisition fails, fallback to an interactive method
                        if (error instanceof InteractionRequiredAuthError) {
                            if (account && inProgress === "none") {
                                instance.acquireTokenPopup({
                                    scopes: protectedResources.apiCert.scopes,
                                }).then((response) => {
                                    callApiWithToken(response.idToken, protectedResources.apiCert.endpoint)
                                    .then(response => setApplicationData(response));
                                }).catch(error => console.log(error));
                            }
                        }
                    });
                }
            }    
        }

        return (
            <div>
                <form onSubmit= {(e) => submitHandler(e) }>
                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-1">Investigation Id</label> 
                        </div>
                        <div className="col-75">
                            <input style = { {height: "30px"} } type="text" id="txtInvestigationId" value = {data.investigation_id} onChange={investigationHandler}/>
                            <span id="invId"  style={{color:'red', display: 'none'}}> Investigation Id - minimum 10 characters [a-z0-9] </span>
                        </div>
                             
                    </div> 

                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-2">Instance Id</label> 
                        </div>
                        <div className="col-75">
                            <input style = { {height: "30px"} } type="text" id="txtInstanceId" value = {data.instance_id} onChange={instanceHandler}/>
                            <span id="instId" style={{color:'red', display: 'none'}}> Instance id - begin with "i-" follow by 10 to 17 characters </span>
                        </div>
                               
                    </div> 
                
                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-3">Os-Type</label> 
                        </div>
                        <div className="col-75">
                            <select  style = { {height: "30px"} } value = {data.os_type1} onChange={osTypeHandler}>
                                <option value="Windows">Windows</option>
                                <option value="Linux">Linux</option>
                            </select>
                        </div>
                    </div>

                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-4">Delete-Stack</label> 
                        </div>
                        <div className="col-75"  onChange = { delStackHandler }>
                            <input  type="radio" name="deleteStack" value="enable"  checked = { data.delete_stack ==="enable"}/> Enable &nbsp;&nbsp;&nbsp;  <input type="radio" name="deleteStack" value="empty"  checked = { data.delete_stack ==="empty"}/> None
                        </div>
                    </div>

                    <div className ="row">
                        <input type="submit" id= "submitButton" value="Submit"/> 
                    </div>                
                </form>
                <div>
                    {outputFlag && 
                         <span>
                           { isLoading ? 
                            <div Style= "overflow-x:auto;">
                                <img src={Spinner} width ="300" height = "100" alt=""/>
								<h4> Request is being processed and Current Status is {status} </h4>
                              
								<table>
									<thead>
										<tr>
											<th>Execution Id</th>
											<th>Progress</th>
											<th>Message</th>
										</tr>
									</thead>
									<tbody>
										<tr>		
											<td>{exectId}</td>
											<td>{status}</td>
											<td>{message}</td>
										</tr>
									</tbody>
								</table>
                            </div> 
                            :
                            <div>
                                 <h4>Request is {finalMsg} </h4>
                            </div>
                            }                            
                        </span>}
                </div>
            </div>
        );
    }