export const callDynamoDB = async (idToken, url, investigationId, axios) => {
    // const header = new Headers();
    const bearer = `${idToken}`;

    // header.append("authorizationToken", bearer);

    // const options = {
    //     method: "GET",
    //     // url: "https://3rn19o2ox8.execute-api.eu-west-1.amazonaws.com/prod/query?type=instance&id=i-0cdfeb2d7dbdd8fa3",
    //     headers: headers,
    //     // body: JSON.stringify(payload),
    //     // mode: 'no-cors',
    //     // mode: "cors",
    //     // credentials: "omit
    //     redirect: 'follow'
    // };

    // const authaxios =  axios.create({
    //     baseURL: url,
    //     headers: header,
        
    // }); 

    const apiEndpoint =`${url}?InvestigationId=${investigationId}`;
    try{
        let  response = await axios.get(apiEndpoint, { headers: { authorizationToken: bearer }});
        return response.data;
    }
    catch(error){
        console.log(error);
    }
       
}
