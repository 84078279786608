import axios from "axios";

export const callApiWithToken = async(idToken, apiEndpoint, payload) => {
    //const headers = new Headers();
    const bearer = `${idToken}`;
    const body = JSON.stringify(payload);
    // headers.append("authorizationToken", bearer);
    // headers.append("Accept", "*");
    // headers.append("Access-Control-Allow", "*");
    // headers.append("Content-Type", "application/json");
    // headers.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    // headers.append("Access-Control-Allow-Origin", "*");
    // headers.append("Access-control-allow-Headers", "*");
    
    // headers.append("X-Correlation-Id",  "DigitalForensics-1234");
//    const headers = {
//        'Content-Type': 'application/json',
//        'Authorization': `Bearer ${idToken}`,
//    )
   
    const options = {
        headers: {
            "authorizationToken": bearer,
            // "Accept": "*",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            // "Access-Control-Allow-Origin": "*",
            // "Access-control-allow-Headers": "*",
        },
        redirect: "follow"
    };

    return axios.post(apiEndpoint, body, options)
        .then(data => data.data)
        .catch(e => console.error(e));
}
